(function ($) {
	$(function () {
		$("header .buttons .open").on("click", function () {
			$("header .menus").slideDown();
			$("header .buttons .open").fadeOut();
		});

		$("header .buttons .close").on("click", function () {
			$("header .menus").slideUp();
			$("header .buttons .open").fadeIn();
		});

		let style	=	"";

		$("h2[data-ruby]").each(function () {
			let $target	=	$(this);

			style	=	"h2[data-ruby='"+ $target.attr("data-ruby") +"']::before {";
			style	+=	'content: "'+ $target.attr("data-ruby") +'";}';

			$("head").append('<style>'+ style +'</style>')
		});

		$("a[href^='#']").on("click", function (e) {
			e.preventDefault();
			
			$("html, body").animate({
				scrollTop	:	$($(this).attr("href")).offset().top - $("header").height(),
			});
		});

		const media	=	window.matchMedia('(max-width: 968px)');
		let swiper	=	null;

		$(".gallary").addClass("swiper");
		$(".gallary > ul").addClass("swiper-wrapper");
		$(".gallary > ul > li").addClass("swiper-slide");

		$(media).on("change", function () {
			if(media.matches) {
				swiper	=	new Swiper(".gallary", {
					speed				:	3000,
					slidesPerView		:	"auto",
					loop				:	true,
					freeMode			:	true,
					freeModeMomentum	:	false,
					autoplay: {
						delay					:	0,
						disableOnInteraction	:	false,
					}
				});
			}
			else {
				if(swiper) {
					swiper.destroy(false,true);
				}
			}
		}).trigger("change");

		$("video").on("play", function () {
			$(this).closest(".video").addClass("playing");
		});
	});
})(jQuery);